import React from "react";
import Footer from "../footer/footer";
import MainBanner from "../../components/banner";
import Banner from '../../assets/images/02-01.svg'

export default function AboutUs() {
    return (
        <div>
            <MainBanner banner={Banner} showCenterCircle={"no"} />
            <div className="mt-20 mx-5 md:mx-20 mb-20">
                <h1 className="text-heading  font-medium mb-5">About Us.</h1>
                <p className="text-para  font-light 2xl:mr-[14rem]">Welcome to Moving 7- the ultimate destination for seamless relocations. Whether you're a relocation company seeking to expand your reach or an individual ready to make your big move, we're here to make the process smoother, smarter, and more efficient.</p>
                <h1 className="text-sub-head [30px] font-medium mb-5 mt-10">Our Mission</h1>
                <p className="text-para [20px] font-light 2xl:mr-[14rem]">
                    At Moving 7, our mission is to transform the relocation experience by connecting relocation companies and individuals through a user-friendly platform that simplifies every aspect of moving. We believe that relocation should be an exciting new chapter, not a stressful ordeal.
                </p>
                <h1 className="text-sub-head [30px] font-medium mt-10 mb-5">Moving 7 for Relocation Companies</h1>
                <p className="text-para [20px] font-light 2xl:mr-[14rem]">Partnering with us means gaining access to a powerful network of potential clients and a suite of tools designed to enhance your service offerings. Our platform provides:</p>

                <ul className="list-disc text-para list-outside mt-3">
                    <li>
                        <span className="font-bold text-para">Increased Visibility : </span> Reach a broader audience and attract new clients with our targeted marketing strategies and high-traffic platform.
                    </li>
                    <li>
                        <span className="font-bold text-para">Streamlined Processes : </span> Benefit from our advanced technology that integrates seamlessly with your systems, making it easier to manage quotes, bookings, and customer interactions.
                    </li>
                    <li>
                        <span className="font-bold text-para"> Data-Driven Insights :</span> Utilize our analytics to gain valuable insights into market trends, customer preferences, and performance metrics, helping you make informed business decisions.
                    </li>
                    <li>
                        <span className="font-bold text-para">Enhanced Customer Experience :</span> Deliver a superior experience with our user-friendly interface and support, ensuring that your clients are satisfied from start to finish.
                    </li>
                </ul>




                <h1 className="text-sub-head [30px] font-medium mt-10 mb-5">Moving 7 for Relocation Clients</h1>
                <p className="text-para [20px] font-light 2xl:mr-[14rem]">Relocating can be overwhelming, but Moving 7 is here to simplify the journey for you. Our platform offers:</p>

                <ul className="list-disc text-para list-outside mt-3">
                    <li>
                        <span className="font-bold text-para ">Comprehensive Listings : </span>  Explore a wide range of relocation companies and services, from movers and packers to storage solutions, all in one place.
                    </li>
                    <li>
                        <span className="font-bold text-para">Tailored Recommendations : </span>  Receive personalized recommendations based on your specific needs and preferences, ensuring you find the best fit for your move.
                    </li>
                    <li>
                        <span className="font-bold text-para ">Transparent Pricing :</span>  Access clear and competitive pricing options, so you know exactly what to expect without any hidden fees.
                    </li>
                    <li>
                        <span className="font-bold text-para">User Reviews and Ratings : </span> Make informed decisions by reading honest reviews and ratings from other customers who have experienced the services firsthand.
                    </li>
                    <li>
                        <span className="font-bold text-para">Expert Advice and Support :</span> Benefit from our resources and customer support team, ready to assist you with any questions or concerns throughout your relocation process.
                    </li>
                </ul>

                <h1 className="text-sub-head [30px] font-medium mt-10 mb-5">Join Us in Revolutionizing Relocation</h1>
                <p className="text-para [20px] font-light 2xl:mr-[14rem]">At Moving 7, we are committed to creating a relocation ecosystem that benefits everyone involved. Our platform is designed to foster meaningful connections and deliver exceptional value. Whether you’re a relocation company looking to expand your reach or an individual preparing for a new home, we invite you to join us in making relocations easier and more enjoyable.
Explore our platform today and discover how we can help you navigate your relocation journey with confidence and ease.
</p>

            </div>
            <Footer />
        </div>
    );
}
