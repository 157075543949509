

const LoaderLayout = ({ children }) => {
    return (
        <div className="loader-layout">
            {children}
        </div>
    );
}
export default LoaderLayout;
